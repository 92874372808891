export const card = {
  display: "flex",
  flexDirection: "column",
};

export const textWrapper = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "secondaryColor",
  marginBottom: "20px",
  padding: "1em",
  width: "30%",
  borderRadius: "5px",
  color: "warn",
};

export const buttonsWrapper = {
  display: "flex",
  justifyContent: "flex-end",
  gap: "1em",
};

export const editButton = {
  backgroundColor: "accentColor",
  color: "#fff",
  padding: "0.4em 2em",
  "&:hover": {
    backgroundColor: "accentColor",
  },
};
