import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { DateInput, TextInput } from "react-admin";
import { useEffect } from "react";
import PaginatedAutoCompleteInput from "components/atoms/PaginatedAutoCompleteInput";
import presentOrPastDate from "../validation/presentOrPastDate";

const UpdateProjectMember = ({
  projectRoles,
  setIsFormDirty,
  handleChangeRoles,
  selectedRoles,
}) => {
  const { watch, formState } = useFormContext();

  const params = watch();

  useEffect(() => {
    setIsFormDirty(formState.isDirty);
  }, [params]);

  return (
    <>
      <TextInput
        label="Member"
        source="employeeName"
        variant="outlined"
        fullWidth
        disabled
      />
      <DateInput
        label="Start Date"
        source="startDate"
        variant="outlined"
        fullWidth
      />
      <DateInput source="endDate" defaultValue={null} variant="outlined" validate={presentOrPastDate} fullWidth />
      <PaginatedAutoCompleteInput
        label="Project Role"
        resource="project-roles"
        onChange={handleChangeRoles}
        optionsRenderer={projectRoles}
        preSelectedValue={selectedRoles}
      />
    </>
  );
};

UpdateProjectMember.propTypes = {
  projectRoles: PropTypes.array,
  setIsFormDirty: PropTypes.func,

  handleChangeRoles: PropTypes.func,
  selectedRoles: PropTypes.array,
};

export default UpdateProjectMember;
