import { useDelete, useNotify } from "react-admin";

const useDeleteResource = ({ record, resource, onSuccess, isHardDelete }) => {
  const notify = useNotify();

  const [deleteOne, { isLoading }] = useDelete(
    resource,
    {
      id: record.id,
      previousData: {...record, isHardDelete},
    },
    {
      onSuccess,
      onError: () => {
        notify("An error occurred while trying to delete");
      },
    },
  );

  return { deleteOne, isLoading };
};

export default useDeleteResource;
