import { useMemo, useState } from "react";
import {
  useRecordContext,
  useResourceContext,
  useRedirect,
  useNotify,
} from "react-admin";
import { capitalizeFirstLetter } from "helpers";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import useDeleteResource from "hooks/useDeleteResource";
import DeleteButton from "components/atoms/buttons/DeleteButton";
import Modal from "components/molecules/modal/Modal";
import { formatToDateWithHyphen } from "utils/DateFormatter";
import { resource as resourceUtils } from "utils/constants";

const { user } = resourceUtils;

const DeleteButtonWithConfirmation = ({isHardDelete}) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();
  const resource = useResourceContext();

  const [showDialog, setShowDialog] = useState(false);
  const [terminationDate, setTerminationDate] = useState(
    formatToDateWithHyphen(new Date()),
  );

  const formattedRecord = useMemo(() => ({
    id: record.id, employment_end: terminationDate,
  }), [record, terminationDate]);

  const onSuccess = () => {
    notify(`${capitalizeFirstLetter(resource).slice(0, -1)} deleted`, {
      messageArgs: { smart_count: 1 },
      undoable: true,
    });
    if (resource === user.name) {
      redirect(
        `/users/status?username=${record.username}&email=${record.email}`,
      );
    } else {
      redirect(`/${resource}`);
    }
  };

  const { deleteOne, isLoading } = useDeleteResource({
    record: formattedRecord,
    resource,
    onSuccess,
    isHardDelete,
  });

  const handleDeleteButton = (event) => {
    event.preventDefault();
    setShowDialog(false);
    
    if (resource === user.name) {
      record.employment_end = terminationDate;
    } else {
      record.deleted_at = terminationDate;
    }

    deleteOne();
  };

  return (
    <>
      <DeleteButton
        handleClick={() => setShowDialog(true)}
        isLoading={isLoading}
      />

      <Modal
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        title={` Are you sure you want to delete this ${resource.slice(
          0,
          -1,
        ) === 'user' ? 'team member': resource.slice(
          0,
          -1,
        )}?`}// impelemntation as placeholder needs to be changed
        action={<DeleteButton handleClick={handleDeleteButton} />}
        confirm
        icon
      >
        {resource !== "projects" && (
          <>
            <div>Please insert termination date</div>
            <br />
            <form noValidate>
              <TextField
                id="date"
                label="Termination date"
                format="YYYY-MM-DDTHH:mm:ss.sssT"
                type="date"
                onChange={(e) => setTerminationDate(e.target.value)}
                defaultValue={terminationDate}
                value={terminationDate}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
              />
            </form>
          </>
        )}
      </Modal>
    </>
  );
};


DeleteButtonWithConfirmation.propTypes = {
  isHardDelete: PropTypes.bool,
};

export default DeleteButtonWithConfirmation;
