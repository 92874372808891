import { Box, Typography } from "@mui/material";
import { CreateButton } from "react-admin";
import { resource } from "utils/constants";

const EmptyProjectsListView = () => {
  const { name: projectName } = resource.projects;

  return (
    <Box
      m={2}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box textAlign="right">
        <CreateButton
          sx={{
            border: 1,
            borderColor: "layout.borderColor",
            color: "accentColor",
            padding: "0.7em 1.2em",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          label={`Create ${projectName.slice(0, -1)}`}
        />
      </Box>

      <Typography variant="h6" paragraph>
        No project available
      </Typography>
    </Box>
  );
};

export default EmptyProjectsListView;
