import { Create, SimpleForm, useNotify, useRedirect } from "react-admin";
import ProjectInformationFormFields from "components/molecules/form_fields/ProjectInformationFormFields";
import {
  NOTIFICATION_TYPE,
  EDIT_RESOURCE_ACTION,
  resource,
} from "utils/constants";

const CreateProject = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify(`Project created`, { type: NOTIFICATION_TYPE.INFO });
    redirect(EDIT_RESOURCE_ACTION, `/${resource.projects.name}`, data.id);
  };

  const onError = () => {
    notify(`An error occurred while trying to create project`, {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  return (
    <Create mutationOptions={{ onSuccess, onError }}>
      <SimpleForm component="div">
        <ProjectInformationFormFields />
      </SimpleForm>
    </Create>
  );
};

export default CreateProject;
