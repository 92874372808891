import {
  DateInput,
  required,
  SelectInput,
  TextInput,
  maxValue,
} from "react-admin";
import PropTypes from "prop-types";
import FormCard from "components/molecules/form_card/FormCard";
import validPhoneNumber from "components/molecules/validation/validPhoneNumber";
import validateEmail from "components/molecules/validation/validateEmail";
import { FORM_CARD, today } from "utils/constants";

const PersonalDetailsFormFields = ({ cities, edit }) => {
  return (
    <FormCard label={FORM_CARD.PERSONAL_DETAILS}>
      <TextInput variant="outlined" source="first_name" validate={required()} />
      <TextInput source="middle_name" variant="outlined" />
      <TextInput source="last_name" validate={required()} variant="outlined" />
      <TextInput
        label={edit && "Personal Email"}
        source="temp_email"
        validate={[required(), validateEmail]}
        variant="outlined"
      />
      <TextInput
        source="phone_number"
        validate={[required(), validPhoneNumber]}
        variant="outlined"
      />

      <DateInput
        source="birth_date"
        validate={[required(), maxValue(today)]}
        variant="outlined"
      />

      <SelectInput
        label="City"
        source="city_id"
        choices={cities}
        optionText="name"
        variant="outlined"
        validate={required()}
      />
    </FormCard>
  );
};

PersonalDetailsFormFields.propTypes = {
  cities: PropTypes.array,
  edit: PropTypes.bool,
};

export default PersonalDetailsFormFields;
