const presentOrPastDate = (value) => {

    const valueDate = new Date(value).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);

    if (today < valueDate ) {
        return "No future dates alowed";
    }
    return undefined;
};

export default presentOrPastDate;