import React, { useEffect, useState, createContext, useCallback } from "react";
import PropTypes from "prop-types";
import { listData } from "../helpers";
import { resource } from "../utils/constants";

const GlobalListsContext = createContext();

const GlobalListsProvider = ({ children }) => {
  const [globalLists, setGlobalLists] = useState({});

  const getListsData = useCallback(async () => {
    const [roles, departments, jobs, cities, projectRoles] = await Promise.all([
      listData(resource.roles),
      listData(resource.departments),
      listData(resource.jobs),
      listData(resource.cities),
      listData(resource.projectRoles),
    ]);
    setGlobalLists({
      roles: roles.data,
      departments: departments.data,
      jobs: jobs.data,
      cities: cities.data,
      projectRoles: projectRoles.data,
    });
  }, []);

  useEffect(() => {
    getListsData();
  }, []);

  return (
    <GlobalListsContext.Provider value={{ globalLists, getListsData }}>
      {children}
    </GlobalListsContext.Provider>
  );
};

GlobalListsProvider.propTypes = {
  children: PropTypes.node,
};

export { GlobalListsContext, GlobalListsProvider };
