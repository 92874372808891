import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router";
import UserIcon from "@mui/icons-material/Group";
import ProjectsIcon from "@mui/icons-material/LibraryBooks";
import authProvider from "./authProvider";
import customDataProvider from "./dataProvider";
import Layout from "components/organisms/layout/Layout";
import LoginPage from "pages/login/LoginPage";
import { UserList } from "components/organisms/forms/users/user_list/userList";
import { NonEmployeeList } from "components/organisms/forms/non_employees/non_employee_list/nonEmployeeList";
import { NonEmployeeCreate } from "components/organisms/forms/non_employees/nonEmployeeCreate";
import { NonEmployeeEdit } from "components/organisms/forms/non_employees/NonEmployeeEdit";
import { GlobalListsProvider } from "providers/GlobalListsProvider";
import CreateUser from "components/organisms/forms/users/user_create/CreateUser";
import EditUser from "components/organisms/forms/users/user_edit/EditUser";
import ProjectsList from "components/organisms/forms/projects/ProjectsList";
import CreateProject from "components/organisms/forms/projects/CreateProject";
import EditProject from "components/organisms/forms/projects/edit_project/EditProject";
import { EditTerminatedUsers } from "components/organisms/forms/terminated_users/EditTerminatedUsers";
import { TerminatedUsersList } from "components/organisms/forms/terminated_users/TerminatedUsersList";
import UserStatusPage from "pages/user_status/UserStatusPage";
import { resource } from "utils/constants";
import { theme } from "config/theme";
import "./App.css";

const dataProvider = customDataProvider(process.env.REACT_APP_API);

const App = () => {
  const { user, userTerminated, nonEmployee, projects } = resource;

  return (
    <GlobalListsProvider>
      <Admin
        loginPage={LoginPage}
        layout={Layout}
        authProvider={authProvider}
        dataProvider={dataProvider}
        theme={theme}
        disableTelemetry
        requireAuth
      >
        <Resource
          name={user.name}
          options={{ label: user.listLabel }}
          list={UserList}
          create={CreateUser}
          edit={EditUser}
          icon={UserIcon}
        />
        <Resource
          name={userTerminated.alias}
          options={{ label: userTerminated.listLabel }}
          list={TerminatedUsersList}
          edit={EditTerminatedUsers}
          icon={UserIcon}
        />
        <Resource
          name={nonEmployee.name}
          create={NonEmployeeCreate}
          options={{ label: nonEmployee.listLabel }}
          list={NonEmployeeList}
          edit={NonEmployeeEdit}
          icon={UserIcon}
        />
        <Resource
          name={projects.name}
          options={{ label: projects.listLabel }}
          list={ProjectsList}
          icon={ProjectsIcon}
          create={CreateProject}
          edit={EditProject}
        />

        <CustomRoutes>
          <Route path="/users/status" element={<UserStatusPage />} />
        </CustomRoutes>
      </Admin>
    </GlobalListsProvider>
  );
};
export default App;
