import { useState } from "react";
import { Button } from "react-admin";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import SearchIcon from "@mui/icons-material/Search";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import SimpleTable from "components/organisms/SimpleTable";
import BaseButton from "components/atoms/buttons/BaseButton";
import Modal from "components/molecules/modal/Modal";
import { callApi } from "services/callApi";

const CheckUserStatusDialog = () => {
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [userStatus, setUserStatus] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [isSearchButtonLoading, setIsSearchButtonLoading] = useState(false);

  const openShowDialog = () => {
    setUsername("");
    setUsernameError(false);
    setEmail("");
    setShowFormDialog(true);
  };

  const validateErrors = () => {
    setUsernameError(username === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSearchButtonLoading(true);
    setUsernameError(username === "");
    if (username !== "") {
      callApi(
        `users/${username}/status?email=${email}`,
      ).then((response) => {
        setShowFormDialog(false);
        setShowStatusDialog(true);
        setUserStatus(response.data);
        setIsSearchButtonLoading(false);
      });
    } else {
      setIsSearchButtonLoading(false);
    }
  };

  return (
    <form>
      <Button label="Check user status" onClick={() => openShowDialog()}>
        <FindInPageIcon />
      </Button>

      <Modal
        open={showFormDialog}
        handleClose={() => setShowFormDialog(false)}
        action={
          <BaseButton
            label="Search"
            type="submit"
            disabled={usernameError}
            onClick={handleSubmit}
            loading={isSearchButtonLoading}
            startIcon={<SearchIcon />}
          />
        }
      >
        <Typography sx={{ textAlign: "left" }}>
          Enter user username and email
        </Typography>
        <form>
          <TextField
            onChange={(e) => setUsername(e.target.value)}
            id="username"
            type="name"
            label="username"
            onKeyUp={validateErrors}
            error={usernameError}
            size="small"
            variant="outlined"
            autoComplete="off"
            fullWidth
            required
          />
          <br />
          <TextField
            id="email"
            type="email"
            label="email"
            variant="outlined"
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            fullWidth
          />
        </form>
      </Modal>

      <Modal
        open={showStatusDialog}
        handleClose={() => setShowStatusDialog(false)}
      >
        {userStatus && (
          <SimpleTable
            data={{
              header: ["System name", "User status"],
              columns: ["system", "status"],
              rows: userStatus,
            }}
          />
        )}
      </Modal>
    </form>
  );
};

export default CheckUserStatusDialog;
