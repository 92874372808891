import { DateField, EmailField, TextField } from "react-admin";
import CustomFilter from "components/molecules/custom_filter/CustomFilter";
import List from "components/organisms/list/List";
import ListActions from "components/organisms/list/ListActions";
import Datagrid from "components/molecules/datagrid/Datagrid";
import { resource } from "utils/constants";

export const UserList = () => {
  return (
    <List
      actions={<ListActions hasCreate />}
      filters={<CustomFilter i />}
      title={resource.user.listLabel}
    >
      <Datagrid>
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="middle_name" />
        <EmailField
          sx={{ color: "accentColor", textDecoration: "none" }}
          source="email"
        />
        <TextField source="phone_number" />
        <DateField source="employment_start" />
      </Datagrid>
    </List>
  );
};
