import { SaveButton, Toolbar } from "react-admin";
import DeleteButtonWithConfirmation from "components/molecules/DeleteButtonWithConfirmation";

const EditProjectToolbar = () => {
  return (
    <Toolbar>
      <SaveButton />
      <DeleteButtonWithConfirmation isHardDelete />
    </Toolbar>
  );
};

export default EditProjectToolbar;
