import { EmailField, TextField } from "react-admin";
import List from "components/organisms/list/List";
import ListActions from "components/organisms/list/ListActions";
import Datagrid from "components/molecules/datagrid/Datagrid";
import CustomFilter from "components/molecules/custom_filter/CustomFilter";
import { resource } from "utils/constants";

export const TerminatedUsersList = (props) => (
  <List
    actions={<ListActions />}
    filters={<CustomFilter />}
    title={resource.userTerminated.listLabel}
    {...props}
  >
    <Datagrid>
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="middle_name" />
      <EmailField
        sx={{ color: "accentColor", textDecoration: "none" }}
        source="email"
      />
      <TextField source="phone_number" />
    </Datagrid>
  </List>
);
