import {
  TextInput,
  DateInput,
  required,
  minLength,
  maxLength,
  maxValue,
} from "react-admin";
import FormCard from "components/molecules/form_card/FormCard";
import validateEmail from "components/molecules/validation/validateEmail";
import { FORM_CARD, today } from "utils/constants";

const PartnersPersonalDetailsFields = () => (
  <FormCard label={FORM_CARD.PERSONAL_DETAILS}>
    <TextInput source="first_name" variant="outlined" validate={required()} />
    <TextInput source="last_name" variant="outlined" validate={required()} />
    <TextInput
      source="email"
      label="Primary email"
      variant="outlined"
      validate={[required(), validateEmail]}
    />
    <TextInput
      source="phone_number"
      variant="outlined"
      validate={[required(), minLength(9), maxLength(15)]}
    />
    <DateInput
      source="birth_date"
      variant="outlined"
      validate={maxValue(today)}
    />
  </FormCard>
);

export default PartnersPersonalDetailsFields;
