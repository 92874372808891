import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import BaseButton from "components/atoms/buttons/BaseButton";

const TeamMemberSection = ({ handleAddNew, children }) => (
  <Box sx={{ width: "100%", margin: "1em 0" }}>
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "fontWeightHeavy",
          marginBottom: "0.7em",
          color: "accentColor",
        }}
      >
        Project Members
      </Typography>
      <BaseButton
        startIcon={<AddIcon />}
        label="Add New"
        customStyles={{
          height: "35px",
          padding: "0em 1.5em",
        }}
        onClick={handleAddNew}
      />
    </Box>
    <Box>{children}</Box>
  </Box>
);

TeamMemberSection.propTypes = {
  handleAddNew: PropTypes.func,
  children: PropTypes.node,
};

export default TeamMemberSection;
