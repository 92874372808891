import { LoginForm } from "react-admin";
import { styled } from "@mui/system";

export const LoginFormWrapper = styled(LoginForm)({
  ".RaLoginForm-content": {
    padding: "2em",
    width: "auto",
  },
  ".MuiFormControl-root": {
    marginBottom: "10px",
  },
  ".RaLoginForm-button": {
    padding: "1em 0",
    background: "#151621",
  },
  ".MuiInputLabel-root": {
    left: "-12px",
  },
  ".MuiFilledInput-input": {
    background: "#fff",
    paddingLeft: 0,
    "&:-webkit-autofill": {
      boxShadow: "0 0 0 30px #fff inset !important",
    },
  },
  ".MuiFormHelperText-root": {
    marginLeft: 0,
  },
});

export const screen = (theme) => ({
  background:
    "radial-gradient(ellipse farthest-side at top right, #1D80FF 0%, #151621 50%)",
  [theme.breakpoints.down("md")]: {
    height: "100vh",
  },
});

export const logo = (theme) => ({
  width: "100%",
  height: "auto",
  [theme.breakpoints.between("md", "lg")]: {
    transform: "scale(0.8)",
  },
});

export const title = (theme) => ({
  fontSize: "3em",
  color: "#fff",
  fontWeight: "bolder",
  margin: "0.5em 0 0",
  [theme.breakpoints.down("lg")]: {
    fontSize: "2.5em",
  },
});

export const formSection = (theme) => ({
  [theme.breakpoints.up("md")]: {
    height: "100vh",
    alignItems: "center",
  },
});

export const form = (theme) => ({
  background: "#fff",
  borderRadius: "7px",
  [theme.breakpoints.down("md")]: {
    height: "300px",
  },
});
