const validPhoneNumber = (values) => {
  if (!values) {
    return "Required";
  }
  if (values.length > 15) {
    return "Must be 15 characters or less";
  }
  if (values.length < 9) {
    return "Must be 9 characters at least";
  }
  if (!/^\+[0-9]*$/.test(values)) {
    return "Invalid phone number ";
  }
  return "";
};

export default validPhoneNumber;
