import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import CancelIcon from "@mui/icons-material/Cancel";

const CancelButton = ({ label, handleClick, isLoading }) => {
  return (
    <LoadingButton
      sx={{
        border: 1,
        borderColor: "layout.borderColor",
        padding: "0.475em 2em",
        backgroundColor: "mainColor",
        color: "accentColor",
        "&:hover": {
          backgroundColor: "mainColor",
        },
        "& .MuiLoadingButton-loadingIndicator": {
          marginLeft: "0.5em",
          color: "accentColor",
        },
      }}
      loading={isLoading}
      loadingPosition="start"
      startIcon={<CancelIcon />}
      onClick={handleClick}
    >
      {label}
    </LoadingButton>
  );
};

CancelButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CancelButton;
