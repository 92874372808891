import { Box, Typography } from "@mui/material";

const NoTeamMember = () => {
  return (
    <Box
      sx={{
        bgcolor: "mainColor",
        boxSizing: "border-box",
        padding: "2em",
        margin: "1em 0",
        border: 1,
        borderColor: "layout.borderColor",
        borderRadius: "5px",
        width: "100%",
      }}
    >
      <Typography sx={{ textAlign: "center", margin: "2em" }}>
        {"You don't have any team members"}
      </Typography>
    </Box>
  );
};

export default NoTeamMember;
