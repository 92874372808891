export const listStyles = {
  "& .RaList-main": {
    paddingTop: "0.5em",

    "& > div:first-of-type": {
      backgroundColor: "secondaryColor",
      padding: "1em !important",
      boxSizing:"border-box",
      borderRadius: "10px",
      minHeight: 0,
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      marginTop: "5px",

      "& .MuiToolbar-root": {
        minHeight: 0,
        padding: 0,
      },

      "& .MuiButton-root": {
        padding: "8.5px 14px",
        border: "1px solid",
        borderColor: "layout.borderColor",
        backgroundColor: "mainColor",
        color: "accentColor",
        borderRadius: "10px",
      },
    },
    "& .RaFilter-form": {
      minHeight: 0,
      margin: 0,
      flex: 1,

      "& .MuiOutlinedInput-root": {
        borderColor: "layout.borderColor",
        width: "100%",
        backgroundColor: "mainColor",
        borderRadius: "10px",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
      "& .filter-field": {
        width: "100%",
      },

      "& .filter-field > div:first-of-type": {
        margin: 0,
        width: "100%",
      },
    },
  },
};
