import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import PropTypes from "prop-types";
import CancelButton from "components/atoms/buttons/CancelButton";
import {
  actions,
  successIcon,
  typography,
  warningIcon,
  centerItems,
} from "components/molecules/modal/modal.styles";

const Modal = ({
  open,
  handleClose,
  disableClose,
  title,
  buttonLabel,
  children,
  confirm,
  icon,
  ...props
}) => {
  const ActionButton = props.action;

  return (
    <MuiDialog open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <Box sx={centerItems}>
          {icon && (
            <Box sx={centerItems}>
              {confirm ? (
                <WarningAmberIcon sx={warningIcon} />
              ) : (
                <Check sx={successIcon} />
              )}
            </Box>
          )}

          <Typography variant="h3" sx={typography}>
            {title}
          </Typography>
        </Box>
        <Box sx={!confirm && centerItems}>{children}</Box>
      </DialogContent>
      <DialogActions sx={actions}>
        {!disableClose && (
          <>
            {ActionButton}
            <CancelButton handleClick={handleClose} label={buttonLabel} />
          </>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

Modal.defaultProps = {
  buttonLabel: "Cancel",
};

Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  disableClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  action: PropTypes.node,
  buttonLabel: PropTypes.string,
  confirm: PropTypes.bool,
  icon: PropTypes.bool,
};

export default Modal;
