import { useContext, useEffect, useState } from "react";
import { Create, SimpleForm } from "react-admin";
import { useNotify, useRedirect } from "ra-core";
import PersonalDetailsFormFields from "components/molecules/form_fields/PersonalDetailsFormFields";
import EmploymentInformationFormFields from "components/molecules/form_fields/EmployeeInformationFormFields";
import { GlobalListsContext } from "providers/GlobalListsProvider";
import ProgressBar from "components/molecules/progress_bar/ProgressBar";
import {
  resource,
  EDIT_RESOURCE_ACTION,
  NOTIFICATION_TYPE,
  userDefaultValues,
} from "utils/constants";
import "components/organisms/forms/users/user_create/create_user.css";

const CreateUser = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify(`User created`, { type: NOTIFICATION_TYPE.INFO });
    redirect(EDIT_RESOURCE_ACTION, `/${resource.user.name}`, data.id);
  };

  const onError = () => {
    notify(`An error occurred while trying to create user`, {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  const { globalLists } = useContext(GlobalListsContext);
  const { roles, departments, jobs, cities } = globalLists;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(globalLists).length !== 0) {
      setIsLoading(false);
    }
  }, [globalLists]);

  if (isLoading) {
    return <ProgressBar />;
  }

  return (
    <Create mutationOptions={{ onSuccess, onError }}>
      <SimpleForm defaultValues={userDefaultValues} component="div">
        <PersonalDetailsFormFields cities={cities} />
        <EmploymentInformationFormFields
          roles={roles}
          departments={departments}
          jobs={jobs}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateUser;
