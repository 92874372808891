import { Edit, SimpleForm, useNotify, useRedirect } from "react-admin";
import { NOTIFICATION_TYPE, resource } from "utils/constants";
import { BarTitle } from "components/atoms/BarTitle";
import EditToolbar from "components/molecules/user_edit_toolbar/UserEditToolbar";
import PartnersPersonalDetailsFields from "components/molecules/form_fields/PartnersPersonalDetailsFields";
import PartnersInformationFields from "components/molecules/form_fields/PartnersInformationFields";

const { nonEmployee } = resource;

export const NonEmployeeEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Partner updated", {
      messageArgs: { smart_count: 1 },
      undoable: true,
    });
    redirect(`/${nonEmployee.name}`);
  };

  const onError = () => {
    notify("An error occurred while trying to update partner", {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  return (
    <Edit title={<BarTitle />} mutationOptions={{ onSuccess, onError }}>
      <SimpleForm toolbar={<EditToolbar />} component="div">
        <PartnersPersonalDetailsFields />
        <PartnersInformationFields />
      </SimpleForm>
    </Edit>
  );
};
