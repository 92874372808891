import { styled } from "@mui/material";

export const StyledBarFiller = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.appBar,
  animation: "progress 6s infinite linear",
  "@keyframes progress": {
    "0%": { width: "0%" },
    "25%": { width: "50%" },
    "50%": { width: "75%" },
    "75%": { width: "85%" },
    "100%": { width: "100%" },
  },
}));
