import { List as RaList, useNotify } from "react-admin";
import { listStyles } from "components/organisms/list/list.styles";
import Pagination from "components/molecules/pagination/Pagination";
import { NOTIFICATION_TYPE } from "utils/constants";

const List = (props) => {
  const notify = useNotify();

  const onError = () => {
    notify(`Cannot load list. Communication error`, {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  return (
    <RaList
      sx={listStyles}
      component="div"
      queryOptions={{ onError }}
      pagination={<Pagination />}
      {...props}
    />
  );
};

export default List;
