import lodashMemoize from "lodash/memoize";

const isEmpty = (value) => {
  return (
    typeof value === "undefined" ||
    value === null ||
    value === "" ||
    (Array.isArray(value) && value.length === 0)
  );
};

// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
function memoize(fn) {
  return lodashMemoize(fn, () => {
    const args = [];
    for (let i = 0; i < arguments.length; i++) {
      args[i] = arguments[i];
    }
    return JSON.stringify(args);
  });
}

const valueBetween = memoize((min, max, message) => {
  if (!message) {
    message = `Value must be between ${min} and ${max}`;
  }
  return (value) => {
    return (!isEmpty(value) && value < min) || value > max
      ? message
      : undefined;
  };
});

export default valueBetween;
