import { Button } from "@mui/material";
import UserStatusCard from "components/molecules/UserStatusCard";

const UserStatusPage = () => {
  const params = new URLSearchParams(location.hash.split("?")[1]);

  const username = params.get("username");
  const email = params.get("email");

  return (
    <>
      <UserStatusCard username={username} email={email} />
      <Button
        sx={{
          backgroundColor: "accentColor",
          "&:hover": { backgroundColor: "accentColor" },
        }}
        href="/#/users"
        variant="contained"
      >
        Return to users list
      </Button>
    </>
  );
};

export default UserStatusPage;
