import { TextInput, required, DateInput } from "react-admin";
import PropTypes from "prop-types";
import FormCard from "components/molecules/form_card/FormCard";
import { FORM_CARD } from "utils/constants";
import presentOrPastDate from "../validation/presentOrPastDate";

const ProjectInformationFormFields = ({ edit }) => (
  <FormCard label={edit ? FORM_CARD.PROJECT.EDIT : FORM_CARD.PROJECT.CREATE}>
    <TextInput
      source="name"
      validate={required()}
      variant="outlined"
      fullWidth
    />
    <DateInput
      source="start_date"
      variant="outlined"
      defaultValue={new Date()}
    />
    <DateInput
      source="end_date"
      variant="outlined"
      validate={presentOrPastDate}
    />
  </FormCard>
);

ProjectInformationFormFields.propTypes = {
  edit: PropTypes.bool,
};

export default ProjectInformationFormFields;
