export const dataGridStyles = {
  "& .RaDatagrid-tableWrapper": {
    marginTop: "1.5em",
  },
  "& .RaDatagrid-table": {
    borderCollapse: "separate",
    "& th": {
      borderBottom: 1,
      borderColor: "secondaryColor",
      fontSize: ".7em",
      fontWeight: "900",
      paddingBottom: "1em",
    },
    "& th svg": {
      display: "block !important",
    },
    "& td": {
      fontSize: "1em",
      borderBottom: 1,
      borderColor: "secondaryColor",
      padding: "5px 16px",
    },
    "& .MuiTableRow-root": {
      "&:hover": {
        backgroundColor: "secondaryColor",
      },
    },
  },
};
