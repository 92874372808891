import { Create, SimpleForm, useNotify, useRedirect } from "react-admin";
import MandatoryPartnersFields from "components/molecules/form_fields/PartnersPersonalDetailsFields";
import NonMandatoryPartnersFields from "components/molecules/form_fields/PartnersInformationFields";
import { resource, NOTIFICATION_TYPE } from "utils/constants";
import { transformPartnersData } from "helpers";

const { nonEmployee } = resource;

export const NonEmployeeCreate = () => {
  const notify = useNotify();

  const redirect = useRedirect();
  const onSuccess = () => {
    notify("Partner created");
    redirect(`/${nonEmployee.name}`);
  };

  const onError = () => {
    notify(`An error occurred while trying to create partner`, {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  return (
    <Create
      mutationOptions={{ onSuccess, onError }}
      transform={transformPartnersData}
    >
      <SimpleForm>
        <MandatoryPartnersFields />
        <NonMandatoryPartnersFields />
      </SimpleForm>
    </Create>
  );
};
