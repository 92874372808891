import { TextField } from "react-admin";
import { resource } from "utils/constants";
import List from "components/organisms/list/List";
import Datagrid from "components/molecules/datagrid/Datagrid";
import CustomFilter from "components/molecules/custom_filter/CustomFilter";
import EmptyProjectsListView from "components/organisms/forms/projects/EmptyProjectsListView";
import ProjectsListActions from "components/organisms/forms/projects/ProjectsListActions";

const ProjectsList = () => (
  <List
    actions={<ProjectsListActions />}
    empty={<EmptyProjectsListView />}
    filters={<CustomFilter i />}
    title={resource.projects.listLabel}
    bulkActionButtons={false}
    sort={{ field: "name", order: "ASC" }}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="start_date" />
      <TextField source="end_date" />
    </Datagrid>
  </List>
);

export default ProjectsList;
