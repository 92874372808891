import { useContext, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  useNotify,
  useRedirect,
  FormDataConsumer,
} from "react-admin";
import UserStatusCard from "components/molecules/UserStatusCard";
import PersonalDetailsFormFields from "components/molecules/form_fields/PersonalDetailsFormFields";
import EmploymentInformationFormFields from "components/molecules/form_fields/EmployeeInformationFormFields";
import TerminationStatusField from "components/molecules/form_fields/termination_status_field/TerminationStatusField";
import { GlobalListsContext } from "providers/GlobalListsProvider";
import UserEditToolbar from "components/molecules/user_edit_toolbar/UserEditToolbar";
import ProgressBar from "components/molecules/progress_bar/ProgressBar";
import { NOTIFICATION_TYPE, resource } from "utils/constants";
import "./edit_user.css";
import { BarTitle } from "components/atoms/BarTitle";

const EditUser = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { globalLists } = useContext(GlobalListsContext);
  const { roles, departments, jobs, cities } = globalLists;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(globalLists).length !== 0) {
      setIsLoading(false);
    }
  }, [globalLists]);

  const onSuccess = () => {
    notify("User updated", {
      messageArgs: { smart_count: 1 },
      undoable: true,
    });
    redirect(`/${resource.user.name}`);
  };

  const onError = () => {
    notify("An error occurred while trying to update user", {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  if (isLoading) {
    return <ProgressBar />;
  }

  return (
    <Edit
      title={<BarTitle />}
      mutationOptions={{ onSuccess, onError }}
      aside={<UserStatusCard />}
    >
      <SimpleForm toolbar={<UserEditToolbar />} component="div">
        <FormDataConsumer>
          {({ formData }) =>
            formData.employment_end !== null && <TerminationStatusField />
          }
        </FormDataConsumer>
        <PersonalDetailsFormFields cities={cities} edit />
        <EmploymentInformationFormFields
          roles={roles}
          departments={departments}
          jobs={jobs}
          edit
        />
      </SimpleForm>
    </Edit>
  );
};

export default EditUser;
