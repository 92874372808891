/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const SimpleTable = (props) => {
  const { header } = props.data;
  const dataColumns = props.data.columns;
  const dataRows = props.data.rows;

  const tableHeaders = (
    <TableHead>
      <TableRow>
        {header.map((column, index) => (
          <TableCell key={index}>
            <b>{column}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const tableBodyNoData = (
    <TableBody>
      <TableRow aria-colspan={50}>
        <TableCell align="center">No Data</TableCell>
      </TableRow>
    </TableBody>
  );

  const tableBody =
    dataRows?.length > 0 ? (
      <TableBody>
        {dataRows?.map((row, index) => (
          <TableRow key={index}>
            {dataColumns.map((column, index) => (
              <TableCell key={index}>{row[column]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    ) : (
      tableBodyNoData
    );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        {tableHeaders}
        {tableBody}
      </Table>
    </TableContainer>
  );
};

SimpleTable.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
  }),
};

export default SimpleTable;
