import DecodeJwt from "jwt-decode";

const authProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${process.env.REACT_APP_API}/token`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: new URLSearchParams({
        username,
        password,
        grant_type: "password",
        client_id: "timeoff",
        response_type: "token",
      }),
    });

    return await fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((token) => {
        if (token.error) throw new Error("Invalid credentials");
        const decodedToken = DecodeJwt(token.access_token);
        localStorage.setItem("token", token.access_token);
        localStorage.setItem("role", decodedToken.role);
        localStorage.setItem("tokenExpiry", decodedToken.exp);
        localStorage.setItem("username", decodedToken.sub);
      })
      .then(async () => {
        const userInfo = new Request(`${process.env.REACT_APP_API}/users/me`, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        });
        const token = localStorage.getItem("token");
        userInfo.headers.set("Authorization", `Bearer ${token}`);
        await fetch(userInfo)
          .then((response) => response.json())
          .then((data) => {
            if (data.status >= 400) {
              // when user doesn't have admin privileges
              throw new Error(data.error);
            }
            localStorage.setItem(
              "fullName",
              `${data.data.first_name} ${data.data.last_name}`,
            );
          });
      });
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject({ redirectTo: "/credentials-required" });
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const expires = new Date(localStorage.getItem("tokenExpiry") * 1000);
    if (localStorage.getItem("token") && new Date() < expires) {
      // logout user when token expires
      setTimeout(() => {
        authProvider.logout().then(() => location.reload());
      }, expires - new Date());
      return Promise.resolve();
    } else {
      authProvider.logout();
      return Promise.reject();
    }
  },
  logout: () => {
    ["token", "role", "tokenExpiry", "username"].forEach((item) =>
      localStorage.removeItem(item),
    );
    return Promise.resolve();
  },
  getIdentity: () => {
    return Promise.resolve({
      fullName: localStorage.getItem("fullName"),
    });
  },
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
