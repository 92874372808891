import { useEffect, useState } from "react";
import { useEditContext } from "react-admin";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import SimpleTable from "components/organisms/SimpleTable";
import BaseButton from "components/atoms/buttons/BaseButton";
import { callApi } from "services/callApi";

const UserStatusCard = ({ username, email }) => {
  const { record, isLoading } = useEditContext();
  const waitingStatus = [{ system: "Loading...", status: "Loading..." }];
  const [userStatus, setUserStatus] = useState(waitingStatus);

  const refreshUserStatus = async () => {
    let url;

    if (record) {
      url = `users/${record.username}/status?email=${record.email}`;
    } else {
      url = `users/${username}/status?email=${email}`;
    }

    if (isLoading) {
      setUserStatus(waitingStatus);
    } else {
      callApi(url).then((r) => setUserStatus(r.data));
    }
  };

  useEffect(refreshUserStatus, [isLoading]);

  return (
    <Box
      sx={{
        backgroundColor: "mainColor",
        borderRadius: "5px",
        border: 1,
        borderColor: "layout.borderColor",
        padding: "1.5em",
      }}
    >
      <SimpleTable
        data={{
          header: ["System name", "User status"],
          columns: ["system", "status"],
          rows: userStatus,
        }}
      />
      <br />
      <BaseButton
        customStyles={{
          padding: "0.5em 3em",
          fontSize: "0.8em",
        }}
        label="Refresh"
        onClick={refreshUserStatus}
      />
    </Box>
  );
};

UserStatusCard.propTypes = {
  username: PropTypes.string,
  email: PropTypes.string,
};

UserStatusCard.defaultProps = {
  username: "",
  email: "",
};

export default UserStatusCard;
