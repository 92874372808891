import { Datagrid as RaDatagrid, useListContext } from "react-admin";
import ProgressBar from "components/molecules/progress_bar/ProgressBar";
import { dataGridStyles } from "components/molecules/datagrid/datagrid.styles";

const Datagrid = (props) => {
  const { isLoading } = useListContext();

  if (isLoading) {
    return <ProgressBar />;
  }

  return (
    <RaDatagrid
      rowClick="edit"
      bulkActionButtons={false}
      sx={dataGridStyles}
      {...props}
    />
  );
};

export default Datagrid;
