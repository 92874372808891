import { Filter, TextInput } from "react-admin";

const CustomFilter = () => (
  <Filter>
    <TextInput
      variant="outlined"
      source="q"
      label=""
      placeholder="Search"
      autoComplete="off"
      InputLabelProps={{ shrink: true }}
      inputProps={{
        sx: {
          "&::placeholder": {
            color: "text.primary",
          },
          "&:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
        },
      }}
      alwaysOn
    />
  </Filter>
);

export default CustomFilter;
