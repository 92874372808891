import { useContext, useEffect, useState } from "react";
import { Edit, SimpleForm } from "react-admin";
import { useNotify, useRedirect } from "ra-core";
import { BarTitle } from "components/atoms/BarTitle";
import { GlobalListsContext } from "providers/GlobalListsProvider";
import PersonalDetailsFormFields from "components/molecules/form_fields/PersonalDetailsFormFields";
import EmploymentInformationFormFields from "components/molecules/form_fields/EmployeeInformationFormFields";
import ProgressBar from "components/molecules/progress_bar/ProgressBar";
import UserStatusCard from "components/molecules/UserStatusCard";
import EditToolbar from "components/molecules/user_edit_toolbar/UserEditToolbar";
import { NOTIFICATION_TYPE, resource } from "utils/constants";

export const EditTerminatedUsers = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { globalLists } = useContext(GlobalListsContext);
  const { roles, departments, jobs, cities } = globalLists;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(globalLists).length !== 0) {
      setIsLoading(false);
    }
  }, [globalLists]);

  const onSuccess = () => {
    notify("Terminated user updated", {
      messageArgs: { smart_count: 1 },
      undoable: true,
    });
    redirect(`/${resource.user.name}`);
  };

  const onError = () => {
    notify("An error occurred while trying to update terminated user", {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  if (isLoading) {
    return <ProgressBar />;
  }

  return (
    <Edit
      title={<BarTitle />}
      mutationOptions={{ onSuccess, onError }}
      aside={<UserStatusCard />}
      {...props}
    >
      <SimpleForm toolbar={<EditToolbar />} component="div">
        <PersonalDetailsFormFields cities={cities} edit />
        <EmploymentInformationFormFields
          roles={roles}
          departments={departments}
          jobs={jobs}
          edit
        />
      </SimpleForm>
    </Edit>
  );
};
