export const typography = {
  fontWeight: "fontWeightHeavy",
  fontSize: "1.2em",
  margin: "1em 0",
};

export const warningIcon = {
  color: "warn",
  padding: "0.3em",
  backgroundColor: "warnLight",
  borderRadius: "50%",
};

export const successIcon = {
  color: "successColor",
  padding: "0.3em",
  backgroundColor: "successLight",
  borderRadius: "50%",
};

export const actions = {
  marginBottom: "2em",
  justifyContent: "center",
};

export const centerItems = {
  textAlign: "center",
};
