export const resource = {
  user: {
    name: "users",
    listLabel: "Employees",
  },
  userTerminated: {
    alias: "terminatedusers",
    name: "users-terminated",
    listLabel: "Terminated Employees",
  },
  nonEmployee: {
    name: "partners",
    listLabel: "Partners",
  },
  projects: {
    name: "projects",
    listLabel: "Projects",
  },
  roles: "roles",
  departments: "departments",
  cities: "cities",
  jobs: "jobs",
  platformTeams: "platform-teams",
  projectRoles: "project-roles",
};

export const NOTIFICATION_TYPE = {
  WARNING: "warning",
  INFO: "info",
};

export const CREATE_RESOURCE = {
  PROJECT: "Create Project",
};

export const API_ACTIONS = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PUT: "PUT",
};

export const EDIT_RESOURCE_ACTION = "edit";

export const FORM_CARD = {
  PERSONAL_DETAILS: "Personal Details",
  EMPLOYEE_PROFILE: "Employee Profile",
  EMPLOYMENT_INFORMATION: "Employment Information",
  TERMINATION_STATUS: "Termination Status",
  PARTNERSHIP_INFORMATION: "Partnership Information",
  PROJECT: {
    EDIT: "Project Details",
    CREATE: "Create A New Project",
  },
};

export const EMPLOYEE_PROJECT_UPDATE_FIELD = "team_member_ids";
export const EMPLOYEE_PLATFORM_UPDATE_FIELD = "member_ids";
export const EMPLOYEE_STL_UPDATE_FIELD = "stl_ids";

export const ADDED = "added";
export const REMOVED = "removed";

export const today = new Date().toJSON().slice(0, 10);

export const userDefaultValues = {
  employment_start: new Date(),
  saved_days_override_from: new Date(),
  job_coefficient: 1,
  current_leave_days_from: new Date(),
  photo_name: "default.jpg",
};
