export const formatToDateWithHyphen = (date) => {
  const d = new Date(date);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  const year = d.getFullYear();

  if (month.toString().length < 2) month = `0${month}`;
  if (day.toString().length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

export const formatToFullDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const convertedDate = date.toLocaleDateString("en-US", options);
  const formattedDate = convertedDate.replace(/,/g, " ");

  return formattedDate;
};
