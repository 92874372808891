import { CreateButton, ExportButton, TopToolbar } from "react-admin";
import { resource } from "utils/constants";

const ProjectsListActions = () => {
  const { name: projectName } = resource.projects;

  return (
    <TopToolbar>
      <CreateButton label={`Create ${projectName.slice(0, -1)}`} />
      <ExportButton />
    </TopToolbar>
  );
};

export default ProjectsListActions;
