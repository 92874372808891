import { useState } from "react";
import { Button, DateInput, useRecordContext } from "react-admin";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import CancelButton from "components/atoms/buttons/CancelButton";
import FormCard from "components/molecules/form_card/FormCard";
import useCancelTermination from "hooks/useCancelTermination";
import { FORM_CARD } from "utils/constants";
import {
  buttonsWrapper,
  card,
  editButton,
  textWrapper,
} from "components/molecules/form_fields/termination_status_field/terminationStatusField.styles";
import { useFormContext } from "react-hook-form";

const TerminationStatusField = () => {
  const record = useRecordContext();

  const { resetField } = useFormContext();

  const [isDisabled, setIsDisabled] = useState(true);

  const { cancelTermination, loading } = useCancelTermination({ record });

  const enableInputField = () => {
    setIsDisabled(!isDisabled);

    if (!isDisabled) {
      resetField("employment_end");
    }
  };

  return (
    <FormCard label={FORM_CARD.TERMINATION_STATUS} customStyles={card}>
      {isDisabled ? (
        <Box sx={textWrapper}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {record.employment_end}
          </Typography>
        </Box>
      ) : (
        <DateInput
          source="employment_end"
          variant="outlined"
          sx={{ width: "40%", marginBottom: "0.9em" }}
        />
      )}

      <Box sx={buttonsWrapper}>
        <Button
          label={isDisabled ? "Edit Date" : "Cancel Edit"}
          onClick={enableInputField}
          sx={editButton}
        />
        <CancelButton
          label="Cancel Termination"
          handleClick={cancelTermination}
          isLoading={loading}
        />
      </Box>
    </FormCard>
  );
};

TerminationStatusField.propTypes = {
  record: PropTypes.object,
};

export default TerminationStatusField;
