import {
  Toolbar,
  SaveButton,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import CancelButton from "components/atoms/buttons/CancelButton";
import DeleteButtonWithConfirmation from "components/molecules/DeleteButtonWithConfirmation";
import { resource as resourceUtils } from "utils/constants";
import useCancelTermination from "hooks/useCancelTermination";

const UserEditToolbar = () => {
  const resource = useResourceContext();
  const record = useRecordContext();

  const { cancelTermination, loading } = useCancelTermination({ record });

  return (
    <Toolbar>
      <SaveButton />
      {resource !== resourceUtils.userTerminated.alias &&
        !record.employment_end && <DeleteButtonWithConfirmation />}
      {resource === resourceUtils.userTerminated.alias && (
        <CancelButton
          label="Cancel Termination"
          handleClick={cancelTermination}
          isLoading={loading}
        />
      )}
    </Toolbar>
  );
};

export default UserEditToolbar;
