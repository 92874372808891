import { stringify } from "query-string";
import { CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE } from "ra-core";
import { fetchJson } from "./fetchJson";
import authProvider from "./authProvider";
import { resource as utilsResource } from "./utils/constants";

const {
  user,
  nonEmployee,
  userTerminated,
  platformTeams,
  projects,
  projectRoles,
} = utilsResource;

/**
 * Maps admin-on-rest queries to a simple REST API
 * @example
 * GET_LIST     => GET http://my.api.url/users?page=1&per_page=10&sort=username:ASC
 */
export default (apiUrl, httpClient = fetchJson) => {
  const a = {
    ą: "a",
    č: "c",
    ę: "e",
    ė: "e",
    į: "i",
    š: "s",
    ų: "u",
    ū: "u",
    ž: "z",
    Ą: "A",
    Č: "C",
    Ę: "E",
    Ė: "E",
    Į: "I",
    Š: "S",
    Ų: "U",
    Ū: "U",
    Ž: "Z",
  };

  function transliterate(word) {
    return word
      .split("")
      .map((char) => {
        return a[char] || char;
      })
      .join("");
  }

  const createNestedProjectDates = (data, resource) => {
    if (resource !== 'projects') {
      return data;
    }
    return {
      ...data,
      "project_period": {
        "start_date": data.start_date,
        "end_date": data.end_date,
      },
    };
  };

  const flattenProjectDates = data => (
      {
        ...data,
        "start_date": data.project_period.start_date,
        "end_date": data.project_period.end_date,
      }
  );

  const flattenProjectsDates = (data, resource) => {
    if (resource !== 'projects') {
      return data;
    }
    return Array.isArray(data) ? data.map(flattenProjectDates) :
        flattenProjectDates(data);
  };

  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {
      headers: new Headers({ Accept: "application/json" }),
    };
    const token = localStorage.getItem("token");
    if (token) {
      options.headers.set("Authorization", `Bearer ${token}`);
    }
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          page: JSON.stringify(page),
          per_page: JSON.stringify(perPage),
          sort: [field, order].join(":"),
          q: params.filter.q,
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY:
        url = `${apiUrl}/${resource}`;
        break;
      case CREATE:
        if (resource === "users/cancelTermination") {
          url = `${apiUrl}/users/${params}/cancel-termination`;
        } else {
          url = `${apiUrl}/${resource}`;
          if (!params.data.email)
            params.data.email = transliterate(
              `${params.data.first_name}.${params.data.last_name}@telesoftas.com`.replaceAll(
                " ",
                "",
              ),
            ).toLowerCase();
        }
        options.method = "POST";
        options.body = JSON.stringify(createNestedProjectDates(params.data, resource));
        break;
      case UPDATE:
        url =
          resource === nonEmployee.name
            ? `${apiUrl}/${resource}`
            : `${apiUrl}/${resource}/${params.id}`;
        options.method = "PUT";
        options.body = JSON.stringify(createNestedProjectDates(params.data, resource));
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}${params.previousData.isHardDelete ? '/hard': ''}`;
        options.method = "DELETE";
        options.body = JSON.stringify(params.previousData);
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param resource
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource) => {
    const { json } = response;
    const listOfResources = [
      user.name,
      `${user.name}/terminated`,
      nonEmployee.name,
      platformTeams,
      projects.name,
    ];

    switch (type) {
      case GET_LIST:
        if (listOfResources.indexOf(resource) !== -1) {
          return {
            data: flattenProjectsDates(json.data.items, resource),
            total: json.data.total_items,
          };
        } else {
          return {
            data: json.data,
            total: Object.keys(json.data).length,
          };
        }
      case GET_ONE:
        return {
          data: flattenProjectsDates(json.data, resource),
        };
      case GET_MANY:
        return {
          data: json.data,
        };
      case CREATE:
        return {
          data: json.data,
        };
      case DELETE:
        return {
          data: resource === nonEmployee.name ? json.data.id : json.id,
        };
      default:
        return { data: json.data };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params) => {
    const listOfResourcesWithHyphen = [platformTeams, projectRoles];

    if (resource === userTerminated.alias) {
      resource = userTerminated.name;
    }

    if (
      resource.includes("-") &&
      listOfResourcesWithHyphen.indexOf(resource) === -1
    ) {
      resource = resource.replace(/-/g, "/");
    }
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);
    return authProvider
      .checkAuth()
      .then(() =>
        httpClient(url, options)
          .then((response) =>
            convertHTTPResponseToREST(response, type, resource, params),
          )
          .catch((e) => Promise.reject(e)),
      )
      .catch((e) => Promise.reject(e));
  };
};
