import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import WorkIcon from "@mui/icons-material/Work";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import { formatToFullDate } from "utils/DateFormatter";

const iconStyles = { fontSize: "0.9em", marginRight: "0.5em" };
const RoleBadge = styled(Typography)(({ theme }) => ({
  width: "fit-content",
  backgroundColor: theme.palette.secondaryColor,
  color: theme.palette.accentColor,
  padding: ".1em 1em",
  borderRadius: "10px",
  marginBottom: "1em",
}));


const TeamMemberCard = ({ 
  teamMember,
  handleClickAnchor,
  teamMemberIndex,
  anchorEls,
  handleOpenEditModal,
  handleCloseAnchor,
  deleteMemberId,
}) => {

  return (
    <Box
      key={teamMember.id}
      sx={{
        backgroundColor: "mainColor",
        padding: "1em",
        marginBottom: "1em",
        borderRadius: "2px",
        opacity: deleteMemberId === teamMemberIndex ? 0.3 : 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: "1em",
            fontWeight: "fontWeightHeavy",
            marginBottom: "0.5em",
          }}
        >
          {`${teamMember.first_name} ${teamMember.last_name}`}
        </Typography>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            onClick={(e) => handleClickAnchor(e, teamMemberIndex)}
            sx={{ padding: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEls[teamMemberIndex]}
            open={Boolean(anchorEls[teamMemberIndex])}
            onClose={() => handleCloseAnchor(teamMemberIndex)}
            PaperProps={{
              style: {
                width: "20ch",
              },
            }}
          >
            <MenuItem
              onClick={() => handleOpenEditModal(teamMemberIndex, teamMember)}
            >
              Edit member
            </MenuItem>
            <MenuItem
              onClick={() => handleCloseAnchor(teamMemberIndex, teamMember.id)}
            >
              Remove from team
            </MenuItem>
          </Menu>
        </div>
      </Box>
      <Box
        sx={{
          "& p": {
            fontSize: "0.8em",
          },
        }}
      >
        <Box display="flex" flexDirection="row" columnGap={'5px'} flexWrap="wrap">
          {teamMember.project_roles.map(({ name }) => 
            <RoleBadge key={name}>
              {name}
            </RoleBadge>)}
        </Box>
        <Typography>
          <WorkIcon sx={iconStyles} />
          {teamMember.job}
        </Typography>
        <Typography>
          <LocationCityIcon sx={iconStyles} />
          {teamMember.city}
        </Typography>
        <Typography>
          <CalendarMonthIcon sx={iconStyles} />
          {formatToFullDate(teamMember.employee_assignment_period.start_date)}
        </Typography>
      </Box>
    </Box>
  );
};

TeamMemberCard.propTypes = {
  teamMember: PropTypes.object,
  handleClickAnchor: PropTypes.func,
  teamMemberIndex: PropTypes.number,
  anchorEls: PropTypes.array,
  handleOpenEditModal: PropTypes.func,
  handleCloseAnchor: PropTypes.func,
  deleteMemberId: PropTypes.number,
};

export default TeamMemberCard;
