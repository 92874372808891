import { Grid, Typography } from "@mui/material";
import {
  form,
  formSection,
  LoginFormWrapper,
  logo,
  screen,
  title,
} from "./login.styles";
import brandLogo from "../../images/logo.png";

const LoginPage = () => {
  return (
    <Grid container sx={screen}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        item
        md={7}
      >
        <Grid item>
          <img src={brandLogo} alt="logo" sx={logo} />
        </Grid>
        <Grid item>
          <Typography variant="h1" sx={title}>
            Employee dashboard
          </Typography>
        </Grid>
      </Grid>
      <Grid container item md={5} justifyContent="center" sx={formSection}>
        <Grid item xs={10} md={10} xl={7} sx={form}>
          <LoginFormWrapper />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
