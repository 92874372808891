import { useState } from "react";
import { Form, SaveButton, useRecordContext } from "react-admin";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Modal from "components/molecules/modal/Modal";
import BaseButton from "components/atoms/buttons/BaseButton";
import UpdateProjectMember from "components/molecules/form_fields/UpdateProjectMember";
import TeamMemberCard from "components/molecules/team_member_card/TeamMemberCard";
import CancelButton from "components/atoms/buttons/CancelButton";
import { callApi } from "services/callApi";
import { API_ACTIONS } from "utils/constants";

const ShowTeamMembers = ({
  teamMembers,
  removeTeamMember,
  triggerMemberChange,
  setTriggerMemberChange,
}) => {
  const projectRecord = useRecordContext();
  const [anchorEls, setAnchorEls] = useState(new Array(teamMembers.length));
  const [openEditModal, setOpenEditModal] = useState(false);
  const [preloadedValues, setPreloadedValues] = useState({});
  const [deleteMemberId, setDeleteMemberId] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [managedRoles, setManagedRoles] = useState({add_role_ids: [], remove_role_ids: []});

  const handleClickAnchor = (event, index) => {
    const tempAnchors = [...anchorEls];
    tempAnchors[index] = event.currentTarget;
    setAnchorEls(tempAnchors);
  };

  const handleCloseAnchor = (index, teamMemberProjectEmployeeId) => {
    const tempAnchors = [...anchorEls];
    tempAnchors[index] = null;
    setAnchorEls(tempAnchors);

    if (teamMemberProjectEmployeeId) {
      setDeleteMemberId(index);
      removeTeamMember(teamMemberProjectEmployeeId);
    }
  };

  const handleOpenEditModal = (index, teamMemberProjectEmployee) => {
    const tempAnchors = [...anchorEls];
    tempAnchors[index] = null;
    setAnchorEls(tempAnchors);
    setOpenEditModal(true);
    setPreloadedValues({
      startDate: teamMemberProjectEmployee.employee_assignment_period.start_date,
      endDate: teamMemberProjectEmployee.employee_assignment_period.end_date,
      projectRoles: teamMemberProjectEmployee.project_roles,
      employeeId: teamMemberProjectEmployee.user_id,
      employeeName: `${teamMemberProjectEmployee.first_name} ${teamMemberProjectEmployee.last_name}`,
      projectEmployeeId: teamMemberProjectEmployee.id,
    });
  };

  const handleUpdateMember = (data) => {
    const {
      id,
      employeeId,
      startDate,
      endDate,
      projectRoleId,
      projectEmployeeId,
    } = data;
    const projectId = projectRecord.id; 

    const body = {
      employee_id: employeeId,
      employee_assignment_period: {
        start_date: startDate,
        end_date: endDate,
      },
      project_role_id: projectRoleId,
    };

    Promise.all([
      callApi(
        `projects/${id}/info/${projectEmployeeId}`,
        API_ACTIONS.PUT,
        body,
      ),
      callApi(`projects/${projectId}/info/${projectEmployeeId}/project-roles`, API_ACTIONS.POST, managedRoles),
    ]).then(() => {
      setTriggerMemberChange(!triggerMemberChange);
      setOpenEditModal(false);
    });
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "auto",
          md: "1fr 1fr 1fr 1fr",
        },
        gridColumnGap: "20px",
      }}
    >
      {teamMembers.map((teamMember, index) => (
        <TeamMemberCard
          key={`team-member-${teamMember.id}`}
          anchorEls={anchorEls}
          teamMember={teamMember}
          deleteMemberId={deleteMemberId}
          teamMemberIndex={index}
          handleClickAnchor={handleClickAnchor}
          handleCloseAnchor={handleCloseAnchor}
          handleOpenEditModal={handleOpenEditModal}
        />
      ))}

      <Modal
        open={openEditModal}
        action={
          <BaseButton
            label="Update"
            onClick={handleUpdateMember}
            disabled={!isFormDirty}
          />
        }
        disableClose
      >
        <Box width="100%">
          <Typography
            variant="h6"
            sx={{
              textAlign: "left",
              marginBottom: "1em",
              color: "accentColor",
              fontWeight: "fontWeightHeavy",
            }}
          >
            Edit Member
          </Typography>
          <Form defaultValues={preloadedValues} onSubmit={handleUpdateMember}>
            <UpdateProjectMember
              projectRoles={({ name }) => name}
              setIsFormDirty={setIsFormDirty}
              selectedRoles={preloadedValues.projectRoles}
              handleChangeRoles={(selectedRoles) => {
                const { projectRoles } = preloadedValues;
                const initialRoleIds = projectRoles.map(({ id }) => id );
                const mapSelectedRolesToId = selectedRoles.reduce((acc, { id }) => ({...acc, [id]: id}) , {});
                const mapInitialRolesToId = initialRoleIds.reduce((acc, roleId) => ({...acc, [roleId]: roleId}) , {});

                const deletedRoleIds  = initialRoleIds.filter((id) =>  !mapSelectedRolesToId[id]);
                const addedRoleIds  = selectedRoles.filter(({ id }) =>  !mapInitialRolesToId[id]).map(({id}) => id );
                
                setManagedRoles({add_role_ids:addedRoleIds, remove_role_ids:deletedRoleIds  });
              }}
            />
            <Box sx={{ marginTop: "1em" }}>
              <SaveButton
                alwaysEnable={isFormDirty || managedRoles.add_role_ids.length || managedRoles.remove_role_ids.length}
                label="Update"
                icon={null}
                sx={{ marginRight: "1em" }}
              />
              <CancelButton
                label="Cancel"
                handleClick={() => setOpenEditModal(false)}
              />
            </Box>
          </Form>
        </Box>
      </Modal>
    </Box>
  );
};

ShowTeamMembers.propTypes = {
  teamMembers: PropTypes.array,
  removeTeamMember: PropTypes.func,
  triggerMemberChange: PropTypes.bool,
  setTriggerMemberChange: PropTypes.func,
};

TeamMemberCard.propTypes = {
  teamMember: PropTypes.object,
  handleClickAnchor: PropTypes.func,
  teamMemberIndex: PropTypes.number,
  anchorEls: PropTypes.array,
  handleOpenEditModal: PropTypes.func,
  handleCloseAnchor: PropTypes.func,
  deleteMemberId: PropTypes.number,
};

export default ShowTeamMembers;
