export const group = {
  bgcolor: "mainColor",
  boxSizing: "border-box",
  padding: "2em",
  marginBottom: "1em",
  border: 1,
  borderColor: "layout.borderColor",
  borderRadius: "5px",
  width: "100%",
};

export const heading = {
  fontWeight: "fontWeightHeavy",
  marginBottom: "0.7em",
  color: "accentColor",
};

export const content = {
  display: "grid",
  gridTemplateColumns: {
    xs: "auto",
    md: "1fr 1fr 1fr",
  },
  gridColumnGap: "20px",
};
