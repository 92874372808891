import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { group, heading, content } from "components/molecules/form_card/formCard.styles";

const FormCard = ({ label, children, customStyles }) => {
  return (
    <Box sx={group}>
      <Typography variant="h6" sx={heading}>
        {label}
      </Typography>
      <Box sx={{ ...content, ...customStyles }}>{children}</Box>
    </Box>
  );
};

FormCard.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  customStyles: PropTypes.object,
};

export default FormCard;
