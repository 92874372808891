import { DateInput, TextInput, required } from "react-admin";
import FormCard from "components/molecules/form_card/FormCard";
import validateEmail from "components/molecules/validation/validateEmail";
import { FORM_CARD } from "utils/constants";

const PartnersInformationFields = () => (
  <FormCard label={FORM_CARD.PARTNERSHIP_INFORMATION}>
    <TextInput
      source="company_email"
      label="Telesoftas email"
      variant="outlined"
      validate={validateEmail}
    />
    <TextInput source="username" variant="outlined" />
    <DateInput
      source="employment_start"
      label="Partnership start date"
      variant="outlined"
      validate={required()}
    />
  </FormCard>
);

export default PartnersInformationFields;
