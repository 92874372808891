const baseUrl = process.env.REACT_APP_API;

export const callApi = async (path, method = "GET", body = null) => {
  return fetch(`${baseUrl}/${path}`, {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then((response) => response.json())
    .catch((error) => error);
};
