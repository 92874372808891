import { useState } from "react";
import { Edit, SimpleForm, useNotify, useRedirect } from "react-admin";
import ProjectInformationFormFields from "components/molecules/form_fields/ProjectInformationFormFields";
import { BarTitle } from "components/atoms/BarTitle";
import EditProjectToolbar from "components/organisms/forms/projects/edit_project/EditProjectToolbar";
import AddProjectMembers from "components/organisms/forms/projects/team_members/AddProjectMembers";
import { NOTIFICATION_TYPE, resource } from "utils/constants";
import { fetchProjectEmployeeData } from "helpers";

const EditProject = () => {
  const { name } = resource.projects;

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Project updated", {
      messageArgs: { smart_count: 1 },
      undoable: true,
    });
    redirect(`/${name}`);
  };

  const onError = () => {
    notify("An error occurred while trying to update project", {
      type: NOTIFICATION_TYPE.WARNING,
    });
  };

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchProjectMembers = async (projectId) => {
    setIsLoading(true);
    const selectedMembers = await fetchProjectEmployeeData(projectId);
    setSelectedMembers(selectedMembers);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Edit title={<BarTitle />} mutationOptions={{ onSuccess, onError }}>
      <SimpleForm component="div" toolbar={<EditProjectToolbar />}>
        <ProjectInformationFormFields edit />
        <AddProjectMembers
          isLoading={isLoading}
          handleFetchProjectMembers={handleFetchProjectMembers}
          selectedMembers={selectedMembers}
        />
      </SimpleForm>
    </Edit>
  );
};

export default EditProject;
