export const withinLayoutStyles = {
  "& .RaAppBar-appBar": {
    backgroundColor: "appBar",
    "& .MuiButton-startIcon": {
      display: "none",
    },
  },
  "& .RaSidebar-fixed": {
    backgroundColor: "mainColor",
  },
  "& .MuiButtonBase-root": {
    "&:hover:active::after": {
      backgroundColor: "transparent",
    },
    "&:focus::after": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiMenuItem-root": {
    margin: "0.3em 1em",
    padding: "0.3em 1em",
    borderRadius: "5px",
    fontSize: "1em",
    color: "text.primary",
    "&:hover": {
      backgroundColor: "secondaryColor",
    },
  },
  "& .MuiListItemIcon-root": {
    color: "text.primary",
  },
  "& .RaMenuItemLink-active": {
    backgroundColor: "secondaryColor",
    color: "appBar",
    "& .RaMenuItemLink-icon": {
      color: "accentColor",
    },
  },
  "& .RaCreate-main": {
    "& .MuiToolbar-root": {
      padding: 0,
      marginBottom: "3em",
    },
  },
  "& .RaEdit-main": {
    columnGap: "15px",
    "& .MuiToolbar-root": {
      padding: 0,
      marginBottom: "3em",
      columnGap: "10px",
    },
  },
  "& .MuiTable-root th": {
    fontSize: "0.8em !important",
  },
  "& .MuiTable-root td": {
    fontSize: "0.8em",
  },
  "& .MuiOutlinedInput-input": {
    "&:-webkit-autofill": {
      boxShadow: "0 0 0 30px #fff inset !important",
    },
  },
};
