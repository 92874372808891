import { useContext, useEffect } from "react";
import { Layout as AdminLayout, useGetIdentity } from "react-admin";
import { GlobalListsContext } from "providers/GlobalListsProvider";
import { withinLayoutStyles } from "components/organisms/layout/layout.styles";

const Layout = (props) => {
  const { identity } = useGetIdentity();
  const { getListsData } = useContext(GlobalListsContext);

  useEffect(() => {
    if (identity?.fullName) getListsData();
  }, [identity]);

  return (
    <AdminLayout
      sx={{
        "& .RaLayout-content": {
          padding: "0 2em",
          backgroundColor: /#\/\w+\/(\d+|create)/g.test(window.location.hash)
            ? "secondaryColor"
            : "mainColor",
          borderLeft: 1,
          borderColor: "layout.borderColor",
        },
        ...withinLayoutStyles,
      }}
      {...props}
    />
  );
};

export default Layout;
