import { useEffect, useState } from "react";
import { useRecordContext, useResourceContext } from "react-admin";
import PropTypes from "prop-types";
import { constructAppBarTitle } from "helpers";

export const BarTitle = () => {
  const resource = useResourceContext();
  const record = useRecordContext();

  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(constructAppBarTitle(resource, record));
  }, [record]);

  return <span>{title}</span>;
};

BarTitle.propTypes = {
  record: PropTypes.object,
};
