import { defaultTheme } from "react-admin";

export const theme = {
  ...defaultTheme,
  palette: {
    mainColor: "#fff",
    secondaryColor: "#F2F2F8",
    accentColor: "#013171",
    appBar: "#1f2031",
    warn: "#CF4628",
    warnLight: "#FFE4DD",
    successColor: "#009668",
    successLight: "#D2F9E3",
    layout: {
      borderColor: "#E7E7E9",
    },
    text: {
      primary: "#1C1C1E",
    },
    button: {
      disabledText: "#666666",
      disabledBgColor: "#cccccc",
    },
  },
  sidebar: {
    width: 250,
    closedWidth: 65,
  },
  typography: {
    fontWeightHeavy: 700,
    fontFamily: "'Assistant', sans-serif",
  },
  components: {
    ...defaultTheme.components,
    RaToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "0.9em",
        },
      },
    },
    RaSaveButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#013171",
          padding: "0.5em 3em",
          "&:hover": {
            backgroundColor: "#013171",
          },
        },
      },
    },
  },
};
