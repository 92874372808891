import { useState } from "react";
import {
  useRefresh,
  useNotify,
  useRedirect,
  useDataProvider,
} from "react-admin";
import { resource, NOTIFICATION_TYPE } from "utils/constants";

const useCancelTermination = ({ record, basePath }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [loading, setLoading] = useState(false);

  const cancelTermination = () => {
    setLoading(true);
    dataProvider
      .create("users/cancelTermination", record.id)
      .then(() => {
        if (basePath === `/${resource.user.name}`) {
          refresh();
        } else {
          redirect("/users");
        }
        notify("User termination canceled");
      })
      .catch(() => {
        notify(`An error occurred while trying to cancel termination`, {
          type: NOTIFICATION_TYPE.WARNING,
        });
      });
  };

  return { cancelTermination, loading };
};

export default useCancelTermination;
