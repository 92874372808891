import { GET_LIST } from "ra-core";
import { callApi } from "services/callApi";
import customDataProvider from "../dataProvider";

const dataProvider = customDataProvider(process.env.REACT_APP_API);

const defaultParams = {
  pagination: {
    page: 1,
    perPage: 10,
  },
  sort: {
    field: "id",
    order: "ASC",
  },
  filter: {},
};

export const listData = (resourceName) => {
  return dataProvider(GET_LIST, resourceName, defaultParams);
};

export const projectEmployeeOptionsRenderer = (choice) =>
  `${choice.first_name} ${choice.last_name}`;

export const transformPartnersData = (data) => ({
  ...data,
  company_email: data.company_email || undefined,
  username: data.username || undefined,
});

export const fetchProjectEmployeeData = async (projectId) => {
  const projectInfoResponse = await callApi(`projects/${projectId}/info`);
  return projectInfoResponse.data;
};

export const filterOutNewProjectMembers = (
  allMembers,
  previousSelectedMembers,
) =>
  allMembers.filter(
    ({ id: allMembersId }) =>
      !previousSelectedMembers.some(
        ({ id: previousSelectedMembersId }) =>
          allMembersId === previousSelectedMembersId,
      ),
  );

export const transformProjectPayload = (members) => {
  return members.map((memberValue) => ({
    employee_id: memberValue.id,
    employee_assignment_period: {
      start_date: memberValue.start_date || new Date(),
      end_date: null,
    },
    project_role_id: 5,
  }));
};

export const getProjectApiProperties = (members, projectId, projectPayload) => {
  let url = "";
  let body = {};

  url =
    members.length > 1
      ? `projects/${projectId}/info/bulk`
      : `projects/${projectId}/info`;

  body =
    members.length > 1
      ? {
          requests: projectPayload,
        }
      : projectPayload[0];

  return { url, body };
};

export const constructAppBarTitle = (resource, record) => {
  if (record) {
    return resource === "projects"
      ? record.name
      : `${record.first_name} ${record.last_name}`;
  }

  return "";
};

export const capitalizeFirstLetter = (resource) => {
  return resource.charAt(0).toUpperCase() + resource.slice(1);
};
