import {
  DateInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  TextInput,
  required,
} from "react-admin";
import PropTypes from "prop-types";
import fieldLatin from "components/molecules/validation/fieldLatin";
import noWhiteSpaces from "components/molecules/validation/noWhiteSpaces";
import valueBetween from "components/molecules/validation/valueBetween";
import validateEmail from "components/molecules/validation/validateEmail";
import FormCard from "components/molecules/form_card/FormCard";
import { FORM_CARD } from "utils/constants";

const EmploymentInformationFormFields = ({
  roles,
  departments,
  jobs,
  edit,
}) => {
  return (
    <FormCard label={FORM_CARD.EMPLOYMENT_INFORMATION}>
      <SelectInput
        label="Role"
        source="role_id"
        optionText="name"
        choices={roles}
        variant="outlined"
        validate={required()}
      />
      <SelectInput
        label="Department"
        source="department_id"
        choices={departments}
        optionText="name"
        variant="outlined"
        validate={required()}
      />
      <SelectInput
        label="Job"
        source="job_id"
        reference="jobs"
        choices={jobs}
        optionText="name"
        variant="outlined"
        validate={required()}
      />
      <TextInput
        source="username"
        variant="outlined"
        validate={[required(), fieldLatin, noWhiteSpaces]}
      />
      <TextInput
        label="TeleSoftas Email"
        source="email"
        variant="outlined"
        validate={edit ? [required(), validateEmail] : validateEmail}
      />
      <NumberInput
        source="tabel_number"
        variant="outlined"
        InputProps={{ inputProps: { min: 1 } }}
      />
      <DateInput source="employment_start" variant="outlined" />
      <NumberInput
        source="job_coefficient"
        variant="outlined"
        validate={[required(), valueBetween(0, 1)]}
        InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 } }}
      />
      {edit && (
        <DateInput
          source="job_coefficient_change_date"
          variant="outlined"
          disabled
        />
        )}
      <DateInput disabled source="employment_end" variant="outlined" />
      <BooleanInput source="on_paid_project" size="small" />
    </FormCard>
  );
};

EmploymentInformationFormFields.propTypes = {
  roles: PropTypes.array,
  departments: PropTypes.array,
  jobs: PropTypes.array,
  edit: PropTypes.bool,
};

export default EmploymentInformationFormFields;
