import { useState, useEffect } from "react";
import { useGetList } from "react-admin";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";

const PaginatedAutoCompleteInput = ({
  name,
  label,
  resource,
  optionsRenderer,
  preSelectedValue,
  ...props
}) => {
  const [value, setValue] = useState(preSelectedValue);
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState([]);
  const [filterText, setFilterText] = useState("");

  const { data, isLoading } = useGetList(resource, {
    pagination: { page, perPage: 50 },
    sort: {
      field: "id",
      order: "ASC",
    },
    filter: { q: filterText },
  });

  const getFilteredOptions = ( preSelectedValues = [], options = []) => {
    return options.filter(
      (item) =>
        !preSelectedValues.some(
          (preselectedItem) => optionsRenderer(preselectedItem) === optionsRenderer(item),
        ),
    );
  };

  useEffect(() => {
    if (page === 1 || filterText) {
      setOptions(getFilteredOptions( value, data));
    } else if (data) {
      const resultingOptions = [...options, ...data];
      
      setOptions(getFilteredOptions(value, resultingOptions));
    }
  }, [data, value]);

  const loadMoreResults = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1 && !filterText) {
      loadMoreResults();
    }
  };

  const handleInputChange = (event, inputValue) => {
    setPage(1);
    setFilterText(inputValue);
  };

  const handleOnChange = (event, data) => {
    setFilterText("");
    setValue(data);
    props.onChange(data);
  };

  return (
    <MuiAutocomplete
      value={value}
      multiple
      loading={isLoading}
      variant="outlined"
      options={options || []}
      openOnFocus
      disableClearable={false}
      filterSelectedOptions
      getOptionLabel={(option) => option && optionsRenderer(option)}
      ChipProps={{
        variant: "outlined",
        deleteIcon: <span />,
      }}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Search"
        />
      )}
      ListboxProps={{
        onScroll: handleScroll,
      }}
    />
  );
};

PaginatedAutoCompleteInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  resource: PropTypes.string,
  optionsRenderer: PropTypes.func,
  preSelectedValue: PropTypes.array,
};

export default PaginatedAutoCompleteInput;
