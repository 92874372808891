import { useState, useEffect, useRef } from "react";
import { useRecordContext } from "react-admin";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Modal from "components/molecules/modal/Modal";
import BaseButton from "components/atoms/buttons/BaseButton";
import PaginatedAutoCompleteInput from "components/atoms/PaginatedAutoCompleteInput";
import TeamMemberSection from "components/organisms/forms/projects/team_members/TeamMemberSection";
import ShowTeamMembers from "components/organisms/forms/projects/team_members/ShowTeamMembers";
import NoTeamMember from "components/organisms/forms/projects/team_members/NoTeamMember";
import { API_ACTIONS, resource } from "utils/constants";
import {
  filterOutNewProjectMembers,
  getProjectApiProperties,
  projectEmployeeOptionsRenderer,
  transformProjectPayload,
} from "helpers";
import ProgressBar from "components/molecules/progress_bar/ProgressBar";
import { callApi } from "services/callApi";

const AddProjectMembers = ({
  isLoading,
  handleFetchProjectMembers,
  selectedMembers,
}) => {
  const [openAddTeamMembersModal, setOpenAddTeamMembersModal] = useState(false);
  const [enableAddMemberBtn, setEnableAddMemberBtn] = useState(false);
  const [triggerMemberChange, setTriggerMemberChange] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const memberValues = useRef([]);
  const isCancel = useRef(false);
  const record = useRecordContext();

  useEffect(() => {
    if (!openAddTeamMembersModal && !isCancel.current) {
      handleFetchProjectMembers(record.id);
    }
    isCancel.current = false;
  }, [openAddTeamMembersModal, triggerMemberChange]);

  const addTeamMembers = () => {
    setIsButtonLoading(true);
    setEnableAddMemberBtn(false);

    const newMembers = filterOutNewProjectMembers(
      memberValues.current,
      selectedMembers,
    );
    const projectPayload = transformProjectPayload(newMembers);
    const { url, body } = getProjectApiProperties(
      newMembers,
      record.id,
      projectPayload,
    );

    callApi(url, API_ACTIONS.POST, body).then(() => {
      setOpenAddTeamMembersModal(false);
      setIsButtonLoading(false);
    });
  };

  const removeTeamMember = (projectEmployeeId) => {
    callApi(
      `projects/${record.id}/info/${projectEmployeeId}/hard`,
      API_ACTIONS.DELETE,
    ).then(() => {
      setTriggerMemberChange(!triggerMemberChange);
    });
  };

  const handleChange = (value) => {
    memberValues.current = value;
    setEnableAddMemberBtn(value.length > 0);
  };

  if (isLoading) {
    return <ProgressBar />;
  }

  return (
    <>
      <TeamMemberSection
        handleAddNew={(e) => {
          e.preventDefault();
          setOpenAddTeamMembersModal(true);
        }}
      >
        {selectedMembers?.length > 0 ? (
          <ShowTeamMembers
            teamMembers={selectedMembers}
            removeTeamMember={removeTeamMember}
            triggerMemberChange={triggerMemberChange}
            setTriggerMemberChange={setTriggerMemberChange}
          />
        ) : (
          <NoTeamMember />
        )}
      </TeamMemberSection>

      <Modal
        open={openAddTeamMembersModal}
        handleClose={() => {
          setEnableAddMemberBtn(false);
          isCancel.current = true;
          setOpenAddTeamMembersModal(false);
        }}
        action={
          <BaseButton
            label="Add Members"
            onClick={addTeamMembers}
            disabled={!enableAddMemberBtn}
            isLoading={isButtonLoading}
          />
        }
      >
        <Box sx={{ width: "100%" }}>
          <PaginatedAutoCompleteInput
            label="Members"
            resource={resource.user.name}
            onChange={handleChange}
            optionsRenderer={projectEmployeeOptionsRenderer}
            preSelectedValue={selectedMembers}
          />
        </Box>
      </Modal>
    </>
  );
};

AddProjectMembers.propTypes = {
  handleFetchProjectMembers: PropTypes.func,
  selectedMembers: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AddProjectMembers;
