import { TopToolbar, CreateButton, ExportButton } from "react-admin";
import { resource } from "utils/constants";

const NonEmployeeListActions = () => (
  <TopToolbar>
    <CreateButton
      label={`Create ${resource.nonEmployee.listLabel.slice(0, -1)}`}
    />
    <ExportButton />
  </TopToolbar>
);

export default NonEmployeeListActions;
