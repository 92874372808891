import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import ActionDelete from "@mui/icons-material/Delete";

const DeleteButton = ({ handleClick, isLoading }) => (
  <LoadingButton
    sx={{
      backgroundColor: "transparent",
      border: 1,
      borderColor: "warn",
      color: "warn",
      padding: "0.4em 2em",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiLoadingButton-loadingIndicator": {
        marginLeft: "0.5em",
        color: "warn",
      },
    }}
    loading={isLoading}
    loadingPosition="start"
    startIcon={<ActionDelete />}
    onClick={handleClick}
  >
    Delete
  </LoadingButton>
);

DeleteButton.propTypes = {
  handleClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DeleteButton;
