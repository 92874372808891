import { CreateButton, ExportButton, TopToolbar } from "react-admin";
import PropTypes from "prop-types";
import CheckUserStatusDialog from "components/organisms/CheckUserStatusDialog";

const ListActions = (props) => (
  <TopToolbar
    sx={{
      "& .MuiToolbar-root": {
        minHeight: "0",
      },
    }}
  >
    <CheckUserStatusDialog />
    {props.hasCreate && <CreateButton label="Create Employee" />}
    <ExportButton />
  </TopToolbar>
);

ListActions.propTypes = {
  hasCreate: PropTypes.bool,
};

export default ListActions;
