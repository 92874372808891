import { Box } from "@mui/system";
import { progressBarStyles } from "./progressBar.styles";
import { StyledBarFiller } from "components/atoms/bar_filler/StyledBarFiller";

const ProgressBar = () => {
  return (
    <Box sx={progressBarStyles}>
      <StyledBarFiller />
    </Box>
  );
};

export default ProgressBar;
