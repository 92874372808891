import { useState } from "react";
import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";

const BaseButton = (props) => {
  const { label, customStyles, isLoading, ...rest } = props;
  const [disable, setDisable] = useState(false);

  const handleClick = (e) => {
    setDisable(true);
    props.onClick && props.onClick(e);
  };

  return (
    <LoadingButton
      sx={{
        backgroundColor: "accentColor",
        padding: "0.475em 2em",
        color: "mainColor",
        "&:hover": {
          backgroundColor: "accentColor",
        },
        "& .MuiLoadingButton-loadingIndicator": {
          color: "mainColor",
        },
        "&:disabled": {
          color: "button.disabledText",
          bgcolor: "button.disabledBgColor",
        },
        ...customStyles,
      }}
      disabled={disable}
      onClick={handleClick}
      loadingPosition="center"
      loading={isLoading}
      {...rest}
    >
      {label}
    </LoadingButton>
  );
};

BaseButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  customStyles: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default BaseButton;
